import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { TermOfUse } from '@app/admin/model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TermOfUseService {
  private route: string = 'admin';
  private controller: string = 'termofuse';
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  Generate(term: TermOfUse): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/generate`,
      term
    );
  }

  Contract(term: TermOfUse): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/contract`,
      term
    );
  }
}
