export enum SerieType {
  Kids = 0,
  Regular = 1,
  Training = 2,
  kidsAndRegular = 3,
  Bonus = 4,
}
export const SerieTypeLabel = new Map<number, string>([
  [SerieType.Kids, 'Kids'],
  [SerieType.Regular, 'Regular'],
  [SerieType.Training, 'Treinamento'],
  [SerieType.kidsAndRegular, 'Kids e Regular'],
  [SerieType.Bonus, 'Bônus'],
]);
