import {
  StudentService,
  InitAdminService,
  AddressService,
} from '@app/admin/services';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Student, State, User } from '@app/admin/model';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MessageService, UtilService } from '@app/shared/services';
import { first } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { DatePipe } from '@angular/common';
import { AccountType } from '@app/shared/enum';
import { UserService } from '@app/auth/services';
import { default as swal } from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import {
  ExperimentalLevel,
  ExperimentalLevelLabel,
} from '@app/shared/enum/ExperimentalLevel';

@Component({ templateUrl: 'student.component.html' })
export class StudentComponent implements OnInit {
  public userId: any;
  studentForm;
  submitted = false;
  returnUrl: string = '/admin/studentlist';

  public flagForeignPerson: boolean = false;
  public validStudent: boolean;
  public errorMessage = '';
  public listErrorMessage: any[];
  private student: Student;
  public updateStudent: boolean = false;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;
  public enumAccountType: AccountType;

  public editMode: boolean;
  public stateList: any = [];
  public stateList$ = new BehaviorSubject([]);
  public citiesList: any = [];
  public citiesList$ = new BehaviorSubject([]);
  public idStudent: number;

  public studentLevelDataList: Array<any> = [];
  public studentLevelList: any = [];
  public studentLevelId: number = 0;
  public studentLevel: any;

  private hideControlsForeignKey: string[] = [
    'cpf',
    'phone',
    'zipCode',
    'number',
    'neighborhood',
    'cityId',
    'stateId',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private studentService: StudentService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private userService: UserService,
    private addressService: AddressService,
    private datePipe: DatePipe
  ) {
    this.phoneMask = [
      '(',
      /[1-9]/,
      /[1-9]/,
      ')',
      ' ',
      /\d?/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
    this.cepMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.onInitForm();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/studentlist';

    this.activatedRoute.params.subscribe((params) => {
      this.getStates();

      this.idStudent = params['id'];
      if (this.idStudent > 0) {
        this.editMode = params['preview'] != 'true';
        this.loadStudent(this.idStudent);
      } else {
        this.editMode = false;
        this.idStudent = null;
        this.userId = params['userId'];
        this.getUserData(this.userId);
      }
    });

    this.onSetEventsForm();
  }

  onInitForm() {
    this.validStudent = true;
    this.flagForeignPerson = false;
    this.getStudentLevelList();
    this.studentForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15),
        ],
      ],
      studentLevel: [ExperimentalLevel.Level6, [Validators.required]],
      cpf: ['', [Validators.required]],
      zipCode: [
        '',
        [Validators.required, Validators.minLength(8), Validators.maxLength(8)],
      ],
      maritalStatus: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      identityCard: [''],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      complement: [''],
      neighborhood: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      cityId: ['', [Validators.required]],
      stateId: ['', [Validators.required]],
      foreignPerson: [false],
      createdOn: [''],
      updatedOn: [''],
    });
  }

  getStudentLevelList() {
    const statusList: Array<object> = [];
    for (var value in ExperimentalLevel) {
      if (Number.isInteger(Number.parseInt(value))) {
        statusList.push({
          value: Number.parseInt(value),
          text: ExperimentalLevelLabel.get(Number.parseInt(value)),
        });
      }
    }
    this.studentLevelDataList = this.getTemplateStudentLevelList(statusList);
  }

  getTemplateStudentLevelList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.studentLevelList = data;
      let arrayStudentLevelList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].value,
          text: data[i].text,
        };
        arrayStudentLevelList.push(element);
      }
      return arrayStudentLevelList;
    }
    return new Array<any>();
  }

  setStudentLevel(value: any) {
    this.studentLevelId = Number.parseInt(value);
    this.studentLevel = this.studentLevelList.find(
      (x: { value: number }) => x.value == this.studentLevelId
    );
  }

  onSetEventsForm() {
    this.f?.phone.valueChanges.subscribe((value: any) => {
      var strPhone = value
        .toString()
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace('_', '')
        .replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = 'INVALID';
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.studentForm.controls;
  }

  setPhone(input: any) {
    if (input?.currentTarget?.value) {
      this.setValueForm('phone', input?.currentTarget?.value);
    }
  }

  setZipCode(input: any) {
    if (input?.currentTarget?.value) {
      this.setValueForm('zipCode', input?.currentTarget?.value);
      if (input?.currentTarget?.value.length >= 8) {
        this.loadAddressByCep(input?.currentTarget);
      }
    }
  }

  private setValueForm(control: any, value: any) {
    this.studentForm.controls[control].setValue(value);
  }

  public isInvalidControls(flagForeignPerson = false): Boolean {
    const controls = this.f;
    for (const name in controls) {
      if (controls[name].invalid && !flagForeignPerson) return true;

      if (
        controls[name].invalid &&
        flagForeignPerson &&
        !this.hideControlsForeignKey.some((e) => e == name)
      )
        return true;
    }

    return false;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validStudent = true;

    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (
      this.studentForm.invalid &&
      this.isInvalidControls(this.flagForeignPerson)
    ) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validStudent = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.student = new Student(this.studentForm.value);
    (this.student.studentLevel = parseInt(this.studentLevelId.toString())),
      this.studentService
        .save(this.student, this.editMode)
        .pipe(first())
        .subscribe(
          (dataReturn) => {
            if (dataReturn.typeResponse == TypeResponse.Success) {
              swal({
                type: 'success',
                title: this.editMode
                  ? 'Aluno(a) atualizado(a) com sucesso!'
                  : 'Novo(a) aluno(a) criado(a) com sucesso!',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.validStudent = true;
                this.router.navigate([this.returnUrl]);
              });
            } else {
              this.validStudent = false;
              this.errorMessage = dataReturn.message;
              if (
                dataReturn.data &&
                dataReturn.data.errors &&
                dataReturn.data.errors.length > 0
              ) {
                this.listErrorMessage = [];
                for (var i = 0; i <= dataReturn.data.errors.length; i++) {
                  this.listErrorMessage.push(
                    dataReturn.data.errors[i].Description
                  );
                }
              }
            }
            this.submitted = false;
            this.loading.hideLoading();
          },
          (error) => {
            this.validStudent = false;
            this.submitted = false;
            this.loading.hideLoading();
            this.errorMessage = !error.ok
              ? 'Erro ao salvar o aluno(a).'
              : error;
          }
        );
  }

  onForeignPerson(input) {
    this.setForeignPerson(input.currentTarget.checked);
  }

  setForeignPerson(uFp) {
    if (uFp) {
      this.hideControlsForeignKey.forEach((element) => {
        this.studentForm.controls[element].disable();
        this.studentForm.controls[element].clearValidators();
      });

      this.f.cityId.value = 0;
      this.f.stateId.value = 0;
      this.f.zipCode.value = '00000000';
      this.f.cpf.value = '00000000000';
    } else {
      this.hideControlsForeignKey.forEach((element) => {
        //for (const name in this.hideControlsForeignKey) {
        this.studentForm.controls[element].enable();
        this.studentForm.controls[element].setValidators(Validators.required);
      });
      this.f.zipCode.setValidators(
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8)
      );
      this.f.zipCode.value = '';
      this.f.cpf.value = '';
    }
    this.studentForm.controls['cityId'].enable();
    this.studentForm.controls['stateId'].enable();
    this.flagForeignPerson = uFp;
    this.f.foreignPerson.value = uFp;
  }

  getStates() {
    this.addressService.getStates().subscribe((response) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.stateList = response.data;
      } else {
        this.stateList = new Array<State>();
      }
      this.stateList$.next(this.stateList);
    });
  }

  getCitiesByStateId(stateId) {
    if (stateId == null || stateId == undefined || stateId == 0) {
      stateId = this.student.stateId;
    }
    if (this.student) {
      this.student.cityId = null;
    }

    this.addressService.getCityByStateId(stateId).subscribe((response) => {
      this.citiesList = response.data;
      this.citiesList$.next(this.citiesList);
    });
  }

  getUserData(userId) {
    if (userId) {
      this.studentForm.controls['userId'].setValue(userId);
      this.userService.getById(userId).subscribe(
        (response) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.studentForm.controls['name'].setValue(response.data.name);
            this.studentForm.controls['email'].setValue(response.data.email);
          } else {
            this.studentForm.controls['name'].setValue('');
            this.studentForm.controls['email'].setValue('');
          }
          this.loading.hideLoading();
        },
        (error) => {
          if (error.error.error === 'invalid_token') {
            this.router.navigate(['/auth/login']);
          }
          this.loading.hideLoading();
        }
      );
    }
  }

  checkStudentCpf(input: { target: { value: any } }) {
    if (
      !input?.target?.value ||
      !this.utilService.isValidCPF(input.target.value)
    ) {
      this.validStudent = false;
      this.f?.cpf.setErrors({ incorrect: true });
      this.errorMessage = 'Valide todos os campos antes de prosseguir.';
      return;
    }
  }

  loadStudent(id) {
    this.loading.showLoading();
    this.studentService.getById(id).subscribe(
      (response) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.validStudent = true;
          this.student = new Student(response.data);
          this.setFormByEntity(this.student);
          this.studentLevelId =
            this.student.studentLevel ?? ExperimentalLevel.Level6;
        } else {
          //this.returnMessage = response.message;
          this.validStudent = true;
        }
        //this.flagSearchUser = true;
        this.loading.hideLoading();
      },
      (error) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
        this.loading.hideLoading();
      }
    );
  }

  loadAddressByCep(zipCode: any) {
    if (zipCode.value && parseInt(zipCode.value) && zipCode.value.length == 8) {
      let cep = zipCode.value;
      this.studentForm.controls['zipCode'].setValue(cep);
      this.addressService.getAddressByCep(cep).subscribe((response) => {
        if (response.erro || response.typeResponse == 2) {
          this.f.cep.setErrors({ invalidNumber: true });
        }
        this.addressService.getCityByUf(response.data.uf).subscribe((p) => {
          this.citiesList = p.data;
          this.citiesList$.next(this.citiesList);
          this.studentForm.controls['street'].setValue(
            response.data.logradouro
          );
          this.studentForm.controls['neighborhood'].setValue(
            response.data.bairro
          );

          this.addressService
            .getCityByIbgeCode(response.data.ibge)
            .subscribe((p) => {
              this.studentForm.controls['cityId'].setValue(p.data.id);
              this.studentForm.controls['stateId'].setValue(p.data.stateId);
            });
        });
      });
    }
  }

  setFormByEntity(data: any) {
    this.studentForm = this.formBuilder.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name),
      birthDate: new FormControl(
        this.datePipe.transform(data.birthDate, 'yyyy-MM-dd')
      ),
      email: new FormControl(data.email),
      phone: new FormControl(data.phone),
      cpf: new FormControl(data.cpf),
      zipCode: new FormControl(data.zipCode),
      street: new FormControl(data.street),
      number: new FormControl(data.number),
      complement: new FormControl(data.complement),
      neighborhood: new FormControl(data.neighborhood),
      userId: new FormControl(data.userId),
      cityId: new FormControl(data.cityId),
      stateId: new FormControl(data.stateId),
      status: new FormControl(data.status),
      maritalStatus: new FormControl(data.maritalStatus),
      nationality: new FormControl(data.nationality),
      identityCard: new FormControl(data.identityCard),
    });
    this.studentForm.controls['userId'].setValue(data.userId);
    this.studentForm.controls['stateId'].setValue(data.stateId);
    this.addressService.getCityByUf(data.stateUf).subscribe((p) => {
      this.citiesList = p.data;
      this.citiesList$.next(this.citiesList);
      this.studentForm.controls['cityId'].setValue(data.cityId);
    });
  }

  finishFunction() {
    var a = 'finish';
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
    // if (this.isEdit) {
    //   //this.edit()
    // } else {
    //   this.save()
    // }
  }
}
