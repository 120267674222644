import { AddCourseRecurrence } from './../../../model/addCourseRecurrence';
import {
  CourseService,
  InitAdminService,
  TeacherService,
  ProductService,
  CourseRecurrenceService,
} from '@app/admin/services';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services';
import {
  AddCourse,
  Course,
  CourseRecurrence,
  Product,
  Teacher,
} from '@app/admin/model';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import {
  CourseType,
  RecurrenceDay,
  Unity,
  RecurrenceDayCss,
  RecurrenceDayLabel,
} from '@app/shared/enum';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { default as swal } from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: 'course.component.html',
})
export class CourseComponent implements OnInit {
  private course: Course;
  private courseData: Course;
  public closeResult: string;
  public courseForm: any;
  public submitted = false;
  private returnUrl: string;
  public validCourse: boolean = true;
  public errorMessage = '';
  public listErrorMessage: any[];
  public valueMask: Array<string | RegExp>;

  public recSource$ = new BehaviorSubject([]);

  public validProduct: boolean;
  public productList: any = [];
  public flagProductSelected: boolean = false;
  public product: Product;

  public validTeacher: boolean;
  public teacherList: any = [];
  public flagTeacherSelected: boolean = false;
  public teacher: Teacher;

  public validTeacherRec: boolean;
  public teacherRecList: any = [];
  public flagTeacherRecSelected: boolean = false;
  public teacherRec: Teacher;

  public editMode: boolean;
  public courseId: number = 0;
  public courseRecurrenceId: number = 0;

  public recurrenceList: Array<CourseRecurrence> = [];
  public flagDayOfWeekSelected: boolean = false;
  public courseRecurrenceForm: any;
  public showTableRecurrence: boolean = false;
  public validCourseRecurrence: boolean = true;
  public dayOfWeekList: any = [];
  public recurrenceValue: any;
  public submittedRecurrence = false;
  public errorMessageRecurrence = '';
  public returnRecurrenceMessage: string =
    'Não existem itens na lista de recorrência.';

  public columnsRecurrence: Array<any> = [
    {
      title: 'Dia da aula',
      name: 'designDayOfWeek',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary',
    },
    {
      title: 'Horário de início',
      name: 'designTimeStart',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary time',
    },
    {
      title: 'Horário de término',
      name: 'designTimeEnd',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary time',
    },
    {
      title: 'Duração',
      name: 'designDuration',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary',
    },
  ];

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private courseRecurrenceService: CourseRecurrenceService,
    private productService: ProductService,
    private teacherService: TeacherService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private router: Router,
    public datepipe: DatePipe
  ) {
    this.valueMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.onInitForm();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/courselist';
    this.recSource$.next(this.recurrenceList);

    this.activatedRoute.params.subscribe((params) => {
      this.onLoadProducts();
      this.onLoadTeachers();
      this.courseId = params['id'];
      if (this.courseId > 0) {
        this.editMode = true;
        this.loadCourseById(this.courseId);
      } else {
        this.editMode = false;
      }
    });

    this.loading.hideLoading();
  }

  onInitForm() {
    this.flagProductSelected = false;
    this.validCourse = true;
    this.validProduct = true;
    this.courseForm = this.formBuilder.group({
      id: [0],
      createdOn: [Date.now],
      updatedOn: [Date.now],
      name: ['', [Validators.required]],
      skypeLink: ['', [Validators.required]],
      productId: [0, [Validators.required]],
      weeklyFrequency: [0, [Validators.required]],
      quantityStudent: [0, [Validators.required]],
      quantityMonth: [0, [Validators.required]],
      experimentalClass: [false],
    });
    this.loadRecurrenceDay();

    this.product = new Product({
      name: '',
      value: 0,
      monthlyAmount: 0,
      unity: Unity.Unity,
      experimentalClass: false,
    });
  }

  onInitRecurrenceForm() {
    this.flagDayOfWeekSelected = false;
    this.validCourseRecurrence = true;
    this.courseRecurrenceForm = this.formBuilder.group({
      id: [0],
      createdOn: [Date.now],
      updatedOn: [Date.now],
      timeStart: ['', [Validators.required]],
      timeEnd: ['', [Validators.required]],
      duration: ['', [Validators.required]],
      dayOfWeek: [0, [Validators.required]],
      courseId: [0, [Validators.required]],
      teacherId: [0, [Validators.required]],
    });
    this.onCalcDuration();
    this.loadRecurrenceDay();
  }

  onLoadProducts() {
    this.productService.all().subscribe((response) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.productList = response.data;
      } else {
        this.productList = new Array<Product>();
      }
    });
  }

  onLoadTeachers() {
    this.teacherService.all().subscribe((response) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherRecList = response.data;
        this.teacherList = response.data;
      } else {
        this.teacherRecList = new Array<Teacher>();
        this.teacherList = new Array<Teacher>();
      }
    });
  }

  onProductChange(productId: number) {
    if (productId > 0) {
      this.courseForm.controls.productId.setValue(productId);
      this.productService.get(productId).subscribe((response) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.product = response.data;
          this.flagProductSelected = true;
        } else {
          this.product = new Product(null);
        }
      });
    }
  }

  onTeacherChange(teacherId: number) {
    this.flagTeacherSelected = false;
    if (teacherId > 0) {
      this.teacherService.get(teacherId).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.teacher = response.data;
          this.flagTeacherSelected = true;
        } else {
          this.teacher = new Teacher(null);
        }
      });
    }
  }

  onTeacherRecChange(teacherId: number) {
    this.flagTeacherRecSelected = false;
    if (teacherId > 0) {
      this.teacherService.get(teacherId).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.teacherRec = response.data;
          this.flagTeacherRecSelected = true;
        } else {
          this.teacherRec = new Teacher(null);
        }
      });
    }
  }

  onCalcDuration() {
    let timeStart = this.courseRecurrenceForm.value.timeStart;
    let timeEnd = this.courseRecurrenceForm.value.timeEnd;
    if (
      timeStart != null &&
      timeStart.length > 0 &&
      timeEnd != null &&
      timeEnd.length > 0
    ) {
      let dtIni = new Date();
      dtIni.setMilliseconds(0);
      dtIni.setSeconds(0);
      dtIni.setMinutes(timeStart.split(':')[1]);
      dtIni.setHours(timeStart.split(':')[0]);
      let dtFim = new Date();
      dtFim.setMilliseconds(0);
      dtFim.setSeconds(1);
      dtFim.setMinutes(timeEnd.split(':')[1]);
      dtFim.setHours(timeEnd.split(':')[0]);

      if (timeEnd === '00:00') {
        dtFim.setDate(dtFim.getDate() + 1);
      }

      let utcDiff = this.formatTimeDiff(dtFim, dtIni);

      if (dtFim <= dtIni || utcDiff === '00:00:00' || utcDiff === '00:00:01') {
        this.validCourseRecurrence = false;
        this.frec.timeEnd.setErrors({ smaller: true });
        this.errorMessage =
          'O horário de término precisa ser maior que o horário de início.';
      } else {
        this.validCourseRecurrence = true;
        this.frec.timeEnd.setErrors(null);
        this.errorMessage = '';
        let duration = utcDiff.split(':')[0] + ':' + utcDiff.split(':')[1];
        this.courseRecurrenceForm.controls['duration'].setValue(duration);
      }
    }
  }

  formatTimeDiff(date1: Date, date2: Date) {
    return Array(3)
      .fill([3600, date1.getTime() - date2.getTime()])
      .map((v, i, a) => {
        a[i + 1] = [a[i][0] / 60, ((v[1] / (v[0] * 1000)) % 1) * (v[0] * 1000)];
        return `0${Math.floor(v[1] / (v[0] * 1000))}`.slice(-2);
      })
      .join(':');
  }

  loadRecurrenceDay() {
    this.recurrenceValue = 0;
    let recurrenceAct: {
      id: number;
      name: string;
      description: string;
    }[] = [];

    for (var n in RecurrenceDay) {
      if (typeof RecurrenceDay[n] === 'number') {
        recurrenceAct.push({
          id: <any>RecurrenceDay[n],
          name: n,
          description: RecurrenceDayLabel.get(<any>RecurrenceDay[n]),
        });
        if (
          this.recurrenceValue != null &&
          this.recurrenceValue.toString() == n
        ) {
          this.recurrenceValue = <any>RecurrenceDay[n];
        }
      }
    }
    this.dayOfWeekList = recurrenceAct;
  }

  onRecurrenceDayChange(value: any) {
    if (value && parseInt(value) > 0) {
      this.courseService.getById(value).subscribe((response) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.course = response.data;
        } else {
          this.course = new Course(null);
        }
      });
    }
  }

  getCourseRecurrenes(courseId: number) {
    this.courseRecurrenceService
      .getByCourseId(courseId)
      .subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.recurrenceList = response.data;
          this.designColumns(this.recurrenceList, true);
          this.showTableRecurrence = true;
        } else {
          this.recurrenceList = new Array<CourseRecurrence>();
          this.showTableRecurrence = false;
          this.errorMessageRecurrence = 'Erro ao exibir as recorrências.';
        }
        this.showTableRecurrence = this.recurrenceList.length > 0;
      });
  }

  get f() {
    return this.courseForm.controls;
  }

  get frec() {
    return this.courseRecurrenceForm.controls;
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    this.validCourse = true;

    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.courseForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validCourse = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    if (
      this.editMode === true &&
      this.f.quantityStudent.value < this.courseData.quantityStudent
    ) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validCourse = false;
      this.errorMessage =
        'A quantidade de alunos não pode ser menor que a atual.';
      return;
    }

    this.course = new Course(this.courseForm.value);
    this.course.courseType =
      this.course.quantityStudent > 1 ? CourseType.SemiVip : CourseType.Vip;

    if (this.editMode) {
      this.courseService
        .save(this.course, true)
        .pipe(first())
        .subscribe(
          (dataReturn) => {
            if (dataReturn.typeResponse == TypeResponse.Success) {
              this.validCourse = true;
              //this.router.navigate([this.returnUrl]);
              this.router.navigate([this.returnUrl]);
            } else {
              this.validCourse = false;
              this.errorMessage = dataReturn.message;
              if (
                dataReturn.data &&
                dataReturn.data.errors &&
                dataReturn.data.errors.length > 0
              ) {
                this.listErrorMessage = [];
                for (var i = 0; i <= dataReturn.data.errors.length; i++) {
                  this.listErrorMessage.push(
                    dataReturn.data.errors[i].Description
                  );
                }
              }
            }
            this.submitted = false;
            this.loading.hideLoading();
          },
          (exception) => {
            this.validCourse = false;
            this.submitted = false;
            this.loading.hideLoading();
            this.errorMessage = exception.error.message;
          }
        );
    } else {
      let recList = new Array<AddCourseRecurrence>();
      this.recurrenceList.forEach((rec) => {
        recList.push(new AddCourseRecurrence(rec, true));
      });
      let addCourse: AddCourse = {
        course: this.course,
        courseId: 0,
        recurrences: recList,
      };

      this.courseService
        .addWithRec(addCourse)
        .pipe(first())
        .subscribe(
          (dataReturn) => {
            if (dataReturn.typeResponse == TypeResponse.Success) {
              this.validCourse = true;
              //this.router.navigate([this.returnUrl]);
              this.router.navigate([this.returnUrl]);
            } else {
              this.validCourse = false;
              this.errorMessage = dataReturn.message;
              if (
                dataReturn.data &&
                dataReturn.data.errors &&
                dataReturn.data.errors.length > 0
              ) {
                this.listErrorMessage = [];
                for (var i = 0; i <= dataReturn.data.errors.length; i++) {
                  this.listErrorMessage.push(
                    dataReturn.data.errors[i].Description
                  );
                }
              }
            }
            this.submitted = false;
            this.loading.hideLoading();
          },
          (exception) => {
            this.validCourse = false;
            this.submitted = false;
            this.loading.hideLoading();
            this.errorMessage = exception.error.message;
          }
        );
    }
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  loadCourseById(id: any) {
    this.loading.showLoading();
    this.courseService
      .getById(id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.validCourse = true;
            this.courseData = new Course(response.data);
            this.courseForm = this.formBuilder.group({
              id: new FormControl(response.data.id),
              name: new FormControl(response.data.name),
              skypeLink: new FormControl(response.data.skypeLink),
              productId: new FormControl(response.data.productId),
              quantityMonth: new FormControl(response.data.quantityMonth),
              createdOn: new FormControl(new Date(response.data.createdOn)),
              updatedOn: new FormControl(new Date(response.data.updatedOn)),
              weeklyFrequency: new FormControl(response.data.weeklyFrequency),
              quantityStudent: new FormControl(response.data.quantityStudent),
              experimentalClass: new FormControl(
                response.data.experimentalClass
              ),
            });
            this.getCourseRecurrenes(id);
          } else {
            //this.returnMessage = response.message;
            this.validCourse = true;
          }
          this.loading.hideLoading();
        },
        (error: any) => {
          if (error.error.error === 'invalid_token') {
            this.router.navigate(['/auth/login']);
          }
        }
      );
  }

  onExperimentalClass(input: any) {
    let uExp = input.currentTarget.checked;
    if (uExp) this.course.experimentalClass = uExp;
    this.courseForm.controls['experimentalClass'].value = uExp;
  }

  getClassRoomUrl(classRoom: any) {
    return `https://meet.jit.si/ec-class-room-${classRoom}`;
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  formatCurrency(currency: any): string {
    if (currency) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(currency);
    }
    return '';
  }

  newRecurrence(content) {
    if (this.recurrenceList.length >= parseInt(this.f.weeklyFrequency.value)) {
      swal({
        type: 'warning',
        title: 'Inclusão não permitida!',
        html: 'O <strong>TOTAL</strong> de recorrências já está completo.',
        showConfirmButton: true,
      });
    } else {
      this.onInitRecurrenceForm();
      this.modalService.dismissAll();
      this.modalService
        .open(content, {
          backdrop: 'static',
          //size: 'sm',
          windowClass: 'animated fade modal-backdrop-transparent',
        })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
            if (result === 'Save') {
              //this.submitChangePassword();
            }
          },
          (reason) => {
            this.closeResult = `Dismissed`;
            //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  submitRecurrence() {
    this.showTableRecurrence = false;
    this.submittedRecurrence = true;
    this.validCourseRecurrence = true;
    this.errorMessageRecurrence = '';

    // stop here if form is invalid
    if (this.courseRecurrenceForm.invalid) {
      this.submittedRecurrence = false;
      this.validCourseRecurrence = false;
      this.errorMessageRecurrence =
        'Dados inválidos. Revise todos os campos antes de continuar.';
      return;
    }

    if (this.recurrenceList.length >= parseInt(this.f.weeklyFrequency.value)) {
      this.submittedRecurrence = false;
      this.validCourseRecurrence = false;
      this.errorMessageRecurrence =
        'O <strong>TOTAL</strong> de recorrências já está completo.';
      return;
    }

    let rec = new CourseRecurrence(this.courseRecurrenceForm.value, false);
    if (rec) {
      if (rec.teacherId) {
        rec.teacher = this.teacherRecList.find(
          (x: { id: Number }) => x.id == rec.teacherId
        );
      }
      this.recurrenceList.push(rec);
      this.cancelRecurrence();
      this.designColumns(this.recurrenceList);
      this.validCourseRecurrence = true;
      this.showTableRecurrence = true;
      this.changeDetectorRefs.detectChanges();
    } else {
      this.validCourseRecurrence = false;
      this.errorMessageRecurrence =
        'Não foi possível realizar a inclusão da recorrência. Verifique as informações.';
    }
    this.submittedRecurrence = false;
    this.errorMessageRecurrence = '';
  }

  cancelRecurrence() {
    this.modalService.dismissAll();
    this.onInitRecurrenceForm();
    this.flagTeacherSelected = false;
    this.flagTeacherRecSelected = false;
  }

  designColumns(lista: any[], edit: boolean = false) {
    lista.forEach(function (e) {
      if (e.dayOfWeek >= 0) {
        let recurrenceDayCss = RecurrenceDayCss.get(e.dayOfWeek);
        let recurrenceDaySrt = RecurrenceDayLabel.get(e.dayOfWeek);
        e.designDayOfWeek =
          "<span class='badge badge-pill w-100 " +
          recurrenceDayCss +
          "'>" +
          recurrenceDaySrt +
          '</span>';

        if (edit) {
          if (e.timeStart) {
            let timeStart = new Date();
            timeStart.setMilliseconds(0);
            timeStart.setSeconds(0);
            timeStart.setMinutes(e.timeStart.split(':')[1]);
            timeStart.setHours(e.timeStart.split(':')[0]);
            e.timeStart = timeStart;
          }
          if (e.timeEnd) {
            let timeEnd = new Date();
            timeEnd.setMilliseconds(0);
            timeEnd.setSeconds(0);
            timeEnd.setMinutes(e.timeEnd.split(':')[1]);
            timeEnd.setHours(e.timeEnd.split(':')[0]);
            e.timeEnd = timeEnd;
          }
          if (e.duration) {
            let duration = new Date();
            duration.setMilliseconds(0);
            duration.setSeconds(0);
            duration.setMinutes(e.duration.split(':')[1]);
            duration.setHours(e.duration.split(':')[0]);
            e.duration = duration;
          }
        }

        if (e.timeStart instanceof Date) {
          let timeStart = new Date(e.timeStart);
          e.designTimeStart =
            "<span class='badge border border-primary text-primary'>" +
            "<i class='fas fa-solid fa-clock'></i> " +
            timeStart.toTimeString().split(' ')[0] +
            '</span>';

          let timeEnd = new Date(e.timeEnd);
          e.designTimeEnd =
            "<span class='badge border border-primary text-primary'>" +
            "<i class='fas fa-solid fa-clock'></i> " +
            timeEnd.toTimeString().split(' ')[0] +
            '</span>';

          let duration = new Date(e.duration);
          e.designDuration =
            "<span class='badge border border-info text-info'>" +
            "<i class='fas fa-solid fa-clock'></i> " +
            duration.toTimeString().split(' ')[0] +
            '</span>';
        }
      }
    });
    this.recurrenceList = lista;
    this.recSource$.next(this.recurrenceList);
  }

  public getRecValue(rec: any) {
    let title = rec.title;
    return title;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  courseTeacherChange(rec: any, content: any) {
    this.courseId = rec.courseId;
    this.courseRecurrenceId = rec.id;
    this.modalService
      .open(content, {
        backdrop: 'static',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  recDelete(rec: any) {
    swal({
      title: 'Excluir Recorrência',
      html: 'Confirma a exclusão da recorrência?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then((willDelete) => {
      if (
        willDelete.value === true &&
        this.recurrenceList.length >= rec != null
      ) {
        let index = this.recurrenceList.findIndex(
          (x: { timeStart: any }) => x.timeStart == rec.timeStart
        );
        if (index > -1) {
          this.recurrenceList.splice(index, 1);
          this.recSource$.next(this.recurrenceList);
        }
      }
    });
  }

  selectEvent(item: any) {
    // do something with selected item
    this.onProductChange(item.id);
  }

  onChangeSearch(val: string) {
    console.log(val);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e: any) {
    console.log(e);
    // do something when input is focused
  }
}
