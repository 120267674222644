<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
  </li>
  <li class="breadcrumb-item active">Dados do Aluno</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div id="panel-2" class="panel">
  <div class="panel-hdr bg-primary-600 bg-primary-gradient">
    <h2>Atualização dos dados do Aluno</h2>
    <div class="panel-toolbar">
      <a
        href="javascript:void(0);"
        class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Collapse"
        title="Minimizar painel"
      >
        <i class="fal fa-minus text-white fw-900"></i>
      </a>
      <a
        href="javascript:void(0);"
        class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Fullscreen"
        title="Maximizar painel"
      >
        <i class="fal fa-expand text-primary fw-900"></i>
      </a>
      <a
        href="javascript:void(0);"
        class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Close"
        title="Fechar painel"
      >
        <i class="fal fa-times text-white fw-900"></i>
      </a>
    </div>
  </div>
  <div class="panel-container show">
    <div class="panel-content p-0">
      <form
        [formGroup]="studentForm"
        novalidate
        class="needs-validation"
        (ngSubmit)="onSubmit()"
      >
        <div class="panel-content">
          <div class="form-row">
            <div class="col-md-12">
              <aw-wizard
                [navBarLayout]="'large-filled-symbols'"
                [awNavigationMode]
                navigateBackward="allow"
                [defaultStepIndex]="0"
                navBarLocation="top"
                navigateForward="allow"
                class="m-l-15 m-r-15"
              >
                <aw-wizard-step
                  id="basicData"
                  stepTitle="Dados básicos"
                  (click)="({ stepIndex: 0 })"
                  [stepId]="1"
                >
                  <ng-template awWizardStepTitle>
                    <span class="hidden-sm-down">Dados básicos</span>
                    <span class="hidden-md-up">Básico</span>
                  </ng-template>
                  <ng-template awWizardStepSymbol>
                    <i class="far fa-user"></i>
                  </ng-template>
                  <hr />
                  <div class="form-group">
                    <div class="row d-flex justify-content-center">
                      <input
                        type="hidden"
                        id="status"
                        formControlName="status"
                      />
                      <input type="hidden" id="id" formControlName="id" />
                      <input
                        type="hidden"
                        id="createdOn"
                        formControlName="createdOn"
                        *ngIf="!editMode"
                      />
                      <input
                        type="hidden"
                        id="updatedOn"
                        formControlName="updatedOn"
                        *ngIf="!editMode"
                      />
                      <div class="col-12">
                        <label class="form-label" for="name"
                          >Nome <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          formControlName="name"
                          class="form-control"
                          placeholder="Informe o nome do aluno"
                          maxlength="50"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.name.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.name.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.name.errors.required">
                            Por favor, informe o nome do aluno.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-6 col-12">
                        <label class="form-label" for="email"
                          >E-mail <span class="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          formControlName="email"
                          class="form-control"
                          placeholder="exemplo@email.com.br"
                          maxlength="100"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.email.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.email.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.email.errors.required">
                            Por favor, informe o e-mail do aluno.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="clearfix mb-3 d-block d-sm-none"></div>
                        <label class="form-label" for="birthDate"
                          >Data de nascimento <span class="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          id="birthDate"
                          formControlName="birthDate"
                          class="form-control"
                          placeholder="Informe a data de nascimento"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.birthDate.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.birthDate.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.birthDate.errors.required">
                            Por favor, informe a data de nascimento.
                          </div>
                          <div *ngIf="f.birthDate.errors.incorrect">
                            A data informada é inválida.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group">
                    <div class="row d-flex justify-content-center mb-3">
                      <div class="col-12 mb-3">
                        <div class="form-group">
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="foreignPerson"
                              formControlName="foreignPerson"
                              checked=""
                              (change)="onForeignPerson($event)"
                            />
                            <label
                              class="custom-control-label"
                              for="foreignPerson"
                              >Mora no exterior ou não possui CPF?</label
                            >
                          </div>
                          <div
                            class="alert alert-success alert-dismissible fade show mb-0 mt-3"
                            role="alert"
                            *ngIf="flagForeignPerson"
                          >
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true"
                                ><i class="fal fa-times"></i
                              ></span>
                            </button>
                            <strong>Nice!</strong> Com essa opção ativa os
                            campos não serão mais obrigatórios.
                            <i class="fal fa-smile text-success"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <label class="form-label" for="cpf"
                          >CPF
                          <span class="text-danger" *ngIf="!flagForeignPerson"
                            >*</span
                          >
                        </label>
                        <input
                          type="text"
                          id="cpf"
                          formControlName="cpf"
                          class="form-control"
                          placeholder="Informe o CPF"
                          maxlength="15"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.cpf.errors
                          }"
                          required
                          (focusout)="checkStudentByCpf($event)"
                        />
                        <div
                          *ngIf="!validStudent && f.cpf.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.cpf.errors.required">
                            Por favor, informe o CPF do aluno.
                          </div>
                          <div *ngIf="f.cpf.errors.incorrect">
                            O CPF informado é inválido.
                          </div>
                          <div *ngIf="f.cpf.errors.already_exists">
                            O CPF informado já está cadastrado na base de alunos
                            da English Coffee.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="clearfix mb-3 d-block d-sm-none"></div>
                        <label class="form-label" for="phone"
                          >Telefone <span class="text-danger">*</span>
                        </label>
                        <input
                          type="phone"
                          id="phone"
                          name="phone"
                          formControlName="phone"
                          class="form-control"
                          maxlength="17"
                          placeholder="(00) 00000-0000"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.phone.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.phone.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.phone.errors.required">
                            Por favor, informe o telefone do aluno.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center mb-3">
                      <div class="col-md-4 col-12">
                        <div class="clearfix mb-3 d-block d-sm-none"></div>
                        <label class="form-label" for="nationality"
                          >Nacionalidade <span class="text-danger">*</span>
                        </label>
                        <input
                          type="nationality"
                          id="nationality"
                          name="nationality"
                          formControlName="nationality"
                          class="form-control"
                          maxlength="50"
                          placeholder="Brasileiro(a)"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.nationality.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.nationality.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.nationality.errors.required">
                            Por favor, informe a nacionalidade do aluno.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="clearfix mb-3 d-block d-sm-none"></div>
                        <label class="form-label" for="maritalStatus"
                          >Estado Civil <span class="text-danger">*</span>
                        </label>
                        <input
                          type="maritalStatus"
                          id="maritalStatus"
                          name="maritalStatus"
                          formControlName="maritalStatus"
                          class="form-control"
                          maxlength="50"
                          placeholder="Solteiro(a)"
                          [ngClass]="{
                            'is-invalid':
                              !validStudent && f.maritalStatus.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.maritalStatus.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.maritalStatus.errors.required">
                            Por favor, informe o estado civil do aluno.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <label class="form-label" for="identityCard">RG</label>
                        <input
                          type="text"
                          id="identityCard"
                          formControlName="identityCard"
                          class="form-control"
                          placeholder="Informe o RG"
                          maxlength="20"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.identityCard.errors
                          }"
                        />
                        <div
                          *ngIf="!validStudent && f.identityCard.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.identityCard.errors.incorrect">
                            O RG informado é inválido.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </aw-wizard-step>
                <aw-wizard-step
                  id="addressData"
                  stepTitle="Endereço"
                  (click)="({ stepIndex: 1 })"
                  [stepId]="2"
                >
                  <ng-template awWizardStepTitle>
                    <span class="hidden-sm-down">Endereço</span>
                    <span class="hidden-md-up">Endereço</span>
                  </ng-template>
                  <ng-template awWizardStepSymbol>
                    <i class="far fa-home"></i>
                  </ng-template>
                  <hr />
                  <div class="form-group" *ngIf="flagForeignPerson">
                    <div class="row d-flex justify-content-center">
                      <div class="col-12">
                        <label class="form-label" for="street"
                          >Endereço <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="street"
                          formControlName="street"
                          class="form-control"
                          placeholder="Informe o endereço, número e complemento"
                          maxlength="100"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.street.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.street.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.street.errors.required">
                            Por favor, informe o endereço.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="flagForeignPerson">
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-6 col-12">
                        <label class="form-label" for="cityName"
                          >Cidade <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="cityName"
                          formControlName="cityName"
                          class="form-control"
                          placeholder="Informe o nome da cidade"
                          maxlength="100"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.cityName.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.cityName.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.cityName.errors.required">
                            Por favor, informe a cidade.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 col-12">
                        <label class="form-label" for="stateUf"
                          >UF <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="stateUf"
                          formControlName="stateUf"
                          class="form-control"
                          placeholder="Informe a sigla do estado"
                          maxlength="5"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.stateUf.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.stateUf.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.stateUf.errors.required">
                            Por favor, informe o estado.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <label class="form-label" for="country"
                          >País <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="country"
                          formControlName="country"
                          class="form-control"
                          placeholder="Informe o país"
                          maxlength="50"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.country.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.country.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.country.errors.required">
                            Por favor, informe o país.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="!flagForeignPerson">
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-6 col-12">
                        <label class="form-label" for="zipCode">CEP</label>
                        <div class="input-group">
                          <input
                            type="text"
                            id="zipCode"
                            formControlName="zipCode"
                            class="form-control"
                            placeholder="Informe o CEP"
                            aria-label="CEP"
                            #zipCode
                            maxlength="8"
                            [ngClass]="{
                              'is-invalid': !validStudent && f.zipCode.errors
                            }"
                            required
                            aria-describedby="btnFindzipCode"
                            (focusout)="loadAddressByCep(zipCode)"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-primary waves-effect waves-themed"
                              type="button"
                              id="btnFindzipCode"
                              (click)="loadAddressByCep(zipCode)"
                            >
                              <i class="fal fa-search"></i>
                            </button>
                            <span
                              *ngIf="cepSearch"
                              class="spinner-border spinner-border-sm ml-2 mt-2"
                            ></span>
                            <span
                              class="mt-2 ml-2 text-primary"
                              *ngIf="!cepSearch"
                            >
                              <i class="fa fa-angle-double-left"></i> Clique
                              aqui para buscar o endereço
                            </span>
                            <span
                              class="mt-2 ml-2 text-primary"
                              *ngIf="cepSearch"
                            >
                              <i class="fa fa-angle-double-left"></i>
                              Consultando CEP informado...
                            </span>
                          </div>
                        </div>
                        <div
                          *ngIf="!validStudent && f.zipCode.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.zipCode.errors.required">
                            Por favor, informe o CEP.
                          </div>
                          <div *ngIf="f.zipCode.errors.invalidNumber">
                            CEP inválido. Por favor, digite outro CEP.
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-12">
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!flagForeignPerson">
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-6">
                        <label class="form-label" for="street"
                          >Endereço <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="street"
                          formControlName="street"
                          class="form-control"
                          placeholder="Informe o endereço"
                          maxlength="100"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.street.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.name.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.street.errors.required">
                            Por favor, informe o endereço.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-label" for="number"
                          >Número <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="number"
                          formControlName="number"
                          class="form-control"
                          placeholder="Informe o número"
                          maxlength="10"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.number.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.number.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.number.errors.required">
                            Por favor, informe o número.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-label" for="complement"
                          >Complemento
                        </label>
                        <input
                          type="text"
                          id="complement"
                          formControlName="complement"
                          class="form-control"
                          placeholder="Informe o complemento"
                          maxlength="30"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.complement.errors
                          }"
                        />
                        <div
                          *ngIf="!validStudent && f.complement.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.complement.errors.required">
                            Por favor, informe o complemento.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!flagForeignPerson">
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-6">
                        <label class="form-label" for="neighborhood"
                          >Bairro <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="neighborhood"
                          formControlName="neighborhood"
                          class="form-control"
                          placeholder="Informe o bairro"
                          maxlength="100"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.neighborhood.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validStudent && f.name.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.neighborhood.errors.required">
                            Por favor, informe o bairro.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <label class="form-label" for="stateId"
                          >Estado <span class="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          id="stateId"
                          for="stateId"
                          formControlName="stateId"
                          placeholder="Informe o estado"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.stateId.errors
                          }"
                          (change)="getCitiesByStateId(f.stateId.value)"
                        >
                          <option
                            *ngFor="let state of stateList"
                            [ngValue]="state.id"
                          >
                            {{ state.uf }}
                          </option>
                        </select>
                        <div
                          *ngIf="!validStudent && f.stateId.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.stateId.errors.required">
                            Por favor, informe o estado.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label class="form-label" for="cityId"
                          >Cidade <span class="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          id="cityId"
                          for="cityId"
                          formControlName="cityId"
                          placeholder="Informe o cidade"
                          [ngClass]="{
                            'is-invalid': !validStudent && f.cityId.errors
                          }"
                        >
                          <option
                            *ngFor="let ct of citiesList"
                            [ngValue]="ct.id"
                          >
                            {{ ct.name }}
                          </option>
                        </select>
                        <div
                          *ngIf="!validStudent && f.cityId.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.cityId.errors.required">
                            Por favor, informe a cidade.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </aw-wizard-step>
              </aw-wizard>
            </div>
          </div>
          <div class="form-group" *ngIf="!validStudent">
            <div class="row d-flex justify-content-center mx-3">
              <div class="col-12">
                <hr />
                <div
                  class="alert alert-warning alert-dismissible text-left fade show mt-3"
                  role="alert"
                  *ngIf="!validStudent"
                >
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                  </button>
                  {{ errorMessage }}
                </div>
                <br />
                <div
                  *ngIf="listErrorMessage && !validStudent"
                  class="invalid-feedback"
                >
                  <div *ngFor="let error of listErrorMessage">{{ error }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <footer-form
      [auxSaveAction]="true"
      [auxCancelAction]="true"
      (saveClick)="onSubmit()"
      (cancelClick)="onCancel()"
    ></footer-form>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <ng-template #mdUpdateMessage let-c="close" let-d="dismiss">
      <div class="modal-header border-bottom border-bottom-1 border-faded">
        <h3 class="modal-title text-muted">Atualização de dados</h3>
      </div>
      <div class="modal-body">
        <div class="alert alert-success alert-dismissible fade show">
          <div class="d-flex align-items-center">
            <div class="alert-icon width-8">
              <span class="icon-stack icon-stack-xl">
                <i class="base-2 icon-stack-3x color-success-400"></i>
                <i class="base-10 text-white icon-stack-1x"></i>
                <i class="ni md-profile color-success-800 icon-stack-2x"></i>
              </span>
            </div>
            <div class="flex-1 pl-1">
              <span class="h2"> Olá aluno(a), </span>
              <br />
              <h5 class="text-justify">
                Seus dados estão desatualizados na nossa base de dados, por isso
                solicitamos que finalize seu cadastro para que possamos melhorar
                nosso atendimento durante seu treinamento.
                <br />
                Caso tenha dúvidas por gentileza entre em contato.
              </h5>
              <h4 class="text-primary fw-700">Equipe English Coffee</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-top border-top-1 border-faded">
        <button
          type="button"
          class="btn btn-secondary waves-effect waves-themed"
          data-dismiss="modal"
          (click)="onUpdate()"
        >
          <i class="fa fa-check-circle mr-1"></i> Ok, Atualizar
        </button>
      </div>
    </ng-template>
  </div>
</div>
