import { StudentCourse, Grades, Product, Teacher, CourseRecurrence } from '.';
import { Base } from '@app/shared/model';
import { CourseType } from '@app/shared/enum';

export class Course extends Base {
  name: string;
  identifier: string;
  weeklyFrequency: number;
  quantityStudent: number;
  quantityMonth: number;
  skypeLink: string;

  classRoom: string;
  classRoomPwd: string;

  courseType: CourseType;

  productId: number;
  product: Product;

  teacherId: number;
  teacher: Teacher;

  experimentalClass: boolean = false;

  grades: Array<Grades>;
  studentCourses: Array<StudentCourse>;
  courseRecurrences: Array<CourseRecurrence>;

  constructor(contentResponse: any) {
    super(contentResponse);

    this.name = contentResponse.name;
    this.skypeLink = contentResponse.skypeLink;
    this.identifier = contentResponse.identifier;
    this.weeklyFrequency = parseInt(contentResponse.weeklyFrequency);
    this.quantityStudent = parseInt(contentResponse.quantityStudent);
    this.quantityMonth = parseInt(contentResponse.quantityMonth);

    this.classRoom = contentResponse.classRoom;
    this.classRoomPwd = contentResponse.classRoomPwd;

    this.experimentalClass = contentResponse.experimentalClass;

    this.productId = parseInt(contentResponse.productId);
    if (contentResponse && contentResponse.productId > 0) {
      this.product = contentResponse.product;
    }

    if (contentResponse.grades && contentResponse.grades.lenght > 0) {
      this.grades = contentResponse.grades;
    }

    if (
      contentResponse.studentCourses &&
      contentResponse.studentCourses.lenght > 0
    ) {
      this.studentCourses = contentResponse.studentCourses;
    }

    if (
      contentResponse.courseRecurrences &&
      contentResponse.courseRecurrences.lenght > 0
    ) {
      this.courseRecurrences = contentResponse.courseRecurrences;
    }

    this.courseType = contentResponse.courseType
      ? parseInt(CourseType[contentResponse.courseType])
      : CourseType.Vip;
  }
}
