import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { User } from '@app/auth/model';

//requiring path and fs modules
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from '@app/shared/services';
import { AccountService } from '@app/auth/services';
import { InitStudentService } from '@app/student/services';
import { SerieType } from '@app/shared/enum';
import { ContentJsonService } from '@app/shared/services/contentJson.service';

@Component({
  selector: 'student-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css'],
})
export class CourseComponent implements OnInit {
  public showTable: boolean = true;
  public returnMessage: string = '';
  public packageContent: Array<any>;
  public contentPath: string;
  public imgFileName: string;
  user: User;
  private userRoles: Array<any>;

  public series: any;

  public showRegularContent: boolean = false;
  public showKidsContent: boolean = false;
  public showBonusContent: boolean = false;

  constructor(
    private router: Router,
    private loading: MessageService,
    private accountService: AccountService,
    private initStudent: InitStudentService,
    private contentJsonService: ContentJsonService
  ) {
    this.user = this.accountService.getUserData();
    this.userRoles = this.accountService.getRolesByUser();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initStudent.setConfigPage();
    this.loadSeries();
    this.checkContentVisibility();
    this.loading.hideLoading();
  }

  private checkContentVisibility() {
    if (this.canSeeAllSeries()) {
      this.showKidsContent = true;
      this.showBonusContent = true;
      this.showRegularContent = true;
    } else if (this.user.userKids) {
      this.showKidsContent = true;
      this.showBonusContent = false;
      this.showRegularContent = false;
    } else {
      this.showKidsContent = false;
      this.showRegularContent = true;
    }
  }

  public loadSeries() {
    this.packageContent = new Array<any>();
    this.contentJsonService.AllSeries().subscribe((response: any) => {
      if (this.showRegularContent) {
        this.packageContent.push(
          response.find((x: any) => x.serieType == 'regular')
        );
      }
      if (this.showKidsContent) {
        this.packageContent.push(
          response.find((x: any) => x.serieType == 'kids')
        );
      }
      if (this.showBonusContent) {
        this.packageContent.push(
          response.find((x: any) => x.serieType == 'bonus')
        );
      }
    });
  }

  replaceNameSerie(serieType: any): string {
    return serieType === 'kids'
      ? 'Grade 2'
      : serieType === 'regular'
      ? 'Grade 1'
      : serieType === 'bonus'
      ? 'Bônus'
      : serieType;
  }

  showContent(type: string, itemSerie: any): void {
    this.loading.showLoading();
    this.router.navigate(['/student/contentlist', { type, serie: itemSerie }]);
  }

  public getContentPath(type: string, serie: string) {
    return this.contentJsonService.getContentPath(type, serie);
  }

  canSeeAllSeries(): boolean {
    let role = 'EST1TS7AL36';
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some((x) => x.keyDefault == role);
    } else {
      return false;
    }
  }
}
