import { User } from './../../auth/model/user';
import { Base } from './../../shared/model/base';
import { State, City, StudentCourse, Grades } from '.';
import { ExperimentalLevel } from '@app/shared/enum/ExperimentalLevel';

export class Student extends Base {
  name: string;
  birthDate: Date;
  email: string;
  phone: string;
  cpf: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;

  city: City;
  cityName: string;
  cityId: number;

  state: State;
  stateUf: string;
  stateId: number;

  country: string;

  maritalStatus: string;
  nationality: string;
  identityCard: string;

  contractGenerated: boolean;
  contractSigned: boolean;
  contractEnvelopeId: string;

  user: User;
  userId: number;
  haveUser: boolean;
  foreignPerson: boolean;

  grades: Array<Grades>;
  studentCourses: Array<StudentCourse>;

  haveNewMessage: boolean;
  lastMessageDatetime: Date;

  studentLevel: ExperimentalLevel;

  constructor(contentResponse: any) {
    super(contentResponse);

    this.name = contentResponse.name;
    (this.birthDate = new Date(contentResponse.birthDate)),
      (this.email = contentResponse.email);
    this.phone = contentResponse.phone;
    this.cpf = contentResponse.cpf;
    this.zipCode = contentResponse.zipCode;
    this.street = contentResponse.street;
    this.number = contentResponse.number;
    this.complement = contentResponse.complement;
    this.neighborhood = contentResponse.neighborhood;

    this.studentLevel = contentResponse.studentLevel;
    if (!this.studentLevel) {
      this.studentLevel = ExperimentalLevel.Level6;
    }

    this.cityId = parseInt(contentResponse.cityId);
    this.cityName = contentResponse.cityName;
    if (contentResponse && contentResponse.cityId > 0) {
      this.city = contentResponse.city;
    }

    this.stateId = parseInt(contentResponse.stateId);
    this.stateUf = contentResponse.stateUf;
    if (contentResponse && contentResponse.stateId > 0) {
      this.state = contentResponse.state;
    }

    this.country = contentResponse.country;

    this.maritalStatus = contentResponse.maritalStatus;
    this.nationality = contentResponse.nationality;
    this.identityCard = contentResponse.identityCard;

    this.userId = parseInt(contentResponse.userId);
    if (contentResponse && contentResponse.userId > 0) {
      this.user = contentResponse.user;
    }
    this.haveUser = contentResponse.haveUser;
    this.foreignPerson = contentResponse.foreignPerson;

    this.contractEnvelopeId = contentResponse.contractEnvelopeId;
    this.contractGenerated = contentResponse.contractGenerated;
    this.contractSigned = contentResponse.contractSigned;

    if (contentResponse.grades && contentResponse.grades.lenght > 0) {
      this.grades = contentResponse.grades;
    }

    this.haveNewMessage = contentResponse.haveNewMessage;
    this.lastMessageDatetime = new Date(contentResponse.lastMessageDatetime);

    if (
      contentResponse.studentCourses &&
      contentResponse.studentCourses.lenght > 0
    ) {
      this.studentCourses = contentResponse.studentCourses;
    }
  }
}
