import { StatusClass } from './../../shared/enum/StatusClass';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { ModuleType } from '@app/shared/enum';

@Injectable({
  providedIn: 'root',
})
export class StudentClassesCourseService {
  private route: string = 'admin';
  private controller: string = 'studentclassescourse';
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getById(id: string): any {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {
      params: params,
    });
  }

  getByStudentCourseId(studentCourseId: string): any {
    let params = new HttpParams();
    params = params.append('studentCourseId', studentCourseId);
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/GetByStudentCourseId`,
      {
        params: params,
      }
    );
  }

  getByStudentId(studentId: string): any {
    let params = new HttpParams();
    params = params.append('studentId', studentId);
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/GetByStudentId`,
      {
        params: params,
      }
    );
  }

  getByCourseId(courseId: string): any {
    let params = new HttpParams();
    params = params.append('courseId', courseId);
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/GetByCourseId`,
      {
        params: params,
      }
    );
  }

  changedClass(
    id: string,
    statusClass: StatusClass,
    module: ModuleType,
    episode: number
  ): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/confirmClass/${id}/module/${module}/episode/${episode}`,
      id
    );
  }

  changeTeacher(
    id: string,
    newTeacherId: any,
    confirmed: boolean = false
  ): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/changeTeacher/${id}/${newTeacherId}/${confirmed}`,
      null
    );
  }

  reschedule(id: any, newDate: any, courseId: number = 0): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/reschedule/${id}/newDate/${newDate}/course/${courseId}`,
      null
    );
  }

  setEpisode(id: any, episode: any): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/setEpisode/class/${id}/episode/${episode}`,
      null
    );
  }

  rescheduleSuggestion(id: any, newDate: any): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/rescheduleSuggestion/${id}/newDate/${newDate}`,
      null
    );
  }

  resetStatusClass(id: string): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/resetStatusClass/${id}`,
      id
    );
  }

  confirmClassByTeacherWithParams(data: any): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/ConfirmClassByTeacherWithParams`,
      data
    );
  }

  fixEpisode(id: any, episodeId: any): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/fixEpisode/${id}/episode/${episodeId}`,
      null
    );
  }

  checkReschedule(data: any): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/CheckReschedule`,
      data
    );
  }
}
