<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/student/home']">Home</a>
  </li>
  <li class="breadcrumb-item active">Biblioteca</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div class="row">
  <div class="col-12">
    <div class="card text-left">
      <div class="card-body">
        <h1 class="display-4">Biblioteca de conteúdo</h1>
        <p class="card-text">
          Nessa seção você encontra os conteúdos para as aulas.
        </p>
      </div>
    </div>
  </div>
</div>
<hr />
<div class="row">
  <div
    class="col-12"
    *ngFor="let content of packageContent; let iContent = index"
  >
    <div class="panel">
      <div class="panel-hdr">
        <h2>
          <span class="fw-300 mr-2"><i>Conteúdo</i></span>
          {{ replaceNameSerie(content.serieType) | uppercase }}
        </h2>
        <div class="panel-toolbar">
          <button
            class="btn btn-panel"
            data-action="panel-collapse"
            data-toggle="tooltip"
            data-offset="0,10"
            data-original-title="Collapse"
          ></button>
          <button
            class="btn btn-panel"
            data-action="panel-fullscreen"
            data-toggle="tooltip"
            data-offset="0,10"
            data-original-title="Fullscreen"
          ></button>
          <button
            class="btn btn-panel"
            data-action="panel-close"
            data-toggle="tooltip"
            data-offset="0,10"
            data-original-title="Close"
          ></button>
        </div>
      </div>
      <div class="panel-container show">
        <div class="panel-content">
          <div class="panel-tag">
            Nesta seção estão reunidas as principais séries
            <strong>{{ replaceNameSerie(content.serieType) }}</strong
            >.
          </div>
          <div class="card-group">
            <div
              class="card border m-auto m-lg-0 mr-1"
              style="max-width: 18rem"
              *ngFor="let serie of content.series; let iSerie = index"
            >
              <img
                [src]="
                  getContentPath(content.serieType, serie.folderName) +
                  serie.thumbnail
                "
                class="card-img-top"
                alt="..."
              />
              <!-- <img [src]="(contentPath + 'regular/' + item.folderName +'/'+ item.thumbnail)" class="card-img-top" alt="..."> -->
              <div class="card-body">
                <h5 class="card-title">{{ serie.name }}</h5>
                <p class="card-text text-justify text-muted">
                  {{ serie.description }}
                </p>
              </div>
              <div class="card-footer">
                <button
                  type="button"
                  class="btn btn-sm btn-primary btn-block waves-effect waves-themed"
                  (click)="showContent(content.serieType, serie.item)"
                >
                  Acessar
                  <span class="fal fa-arrow-right mr-1"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="row mt-3" *ngIf="showRegularContent">
  <hr />
  <div class="col-12">
    <h1 class="text-primary">Grade 1</h1>
    <div class="card-group">
      <div
        class="card border m-auto m-lg-0"
        style="max-width: 18rem"
        *ngFor="let item of this.packageContentRegular.series; let i = index"
      >
        <img
          [src]="contentPathRegular + item.folderName + '/' + item.thumbnail"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body">
          <h5 class="card-title">{{ item.name }}</h5>
          <p class="card-text text-justify text-muted">
            {{ item.description }}
          </p>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary btn-block waves-effect waves-themed"
            (click)="showContent(item.item, 1)"
          >
            Acessar
            <span class="fal fa-arrow-right mr-1"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3" *ngIf="showKidsContent">
  <hr />
  <div class="col-12">
    <h1 class="text-primary">Grade 2</h1>
    <div class="card-group">
      <div
        class="card border m-auto m-lg-0"
        style="max-width: 18rem"
        *ngFor="let item of this.packageContentKids.series; let i = index"
      >
        <img
          [src]="contentPathKids + item.folderName + '/' + item.thumbnail"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body">
          <h5 class="card-title">{{ item.name }}</h5>
          <p class="card-text text-justify text-muted">
            {{ item.description }}
          </p>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary btn-block waves-effect waves-themed"
            (click)="showContent(item.item, 0)"
          >
            Acessar
            <span class="fal fa-arrow-right mr-1"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3" *ngIf="showBonusContent">
  <hr />
  <div class="col-12">
    <h1 class="text-primary">Bônus</h1>
    <div class="card-group">
      <div
        class="card border m-auto m-lg-0"
        style="max-width: 18rem"
        *ngFor="let item of this.packageContentBonus.series; let i = index"
      >
        <img
          [src]="contentPathBonus + item.folderName + '/' + item.thumbnail"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body">
          <h5 class="card-title">{{ item.name }}</h5>
          <p class="card-text text-justify text-muted">
            {{ item.description }}
          </p>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary btn-block waves-effect waves-themed"
            (click)="showContent(item.item, 4)"
          >
            Acessar
            <span class="fal fa-arrow-right mr-1"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <hr>
<div class="row">
    <div class="col-12">
        <h1>Treinamento</h1>
        <div class="card-group">
            <div class="card border m-auto m-lg-0" style="max-width: 18rem;" *ngFor="let item of this.packageContent.series; let i = index">
                <img [src]="(contentPath + item.folderName +'/'+ item.thumbnail)" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">{{item.name}}</h5>
                    <p class="card-text text-justify text-muted">{{item.description}}</p>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-sm btn-primary btn-block waves-effect waves-themed" (click)="showContent(item.item)">
                        Acessar
                        <span class="fal fa-arrow-right mr-1"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div> -->
